<template>
  <div class="row dzs-profile">
    <!-- Bloco subconta -->
    <div class="col-lg-4 col-md-5">

      <div class="card">
        <div class="card-header">
          <h4 class="title">Criar Subconta</h4>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-md-12">
                <fg-input label="Novo usuário"
                          name="Novo usuario"
                          v-validate="{required: true, min: 8}"
                          v-model="derivedAccount.newUsername">
                </fg-input>
              </div>
              <div class="col-md-12" v-if="!messageUserUnavailableDisabled">
                <p><span style="font-weight: lighter; color: red !important; font-size: small" class="text-muted">Usuário já existente, tente outro nome.</span></p>
              </div>
            </div>
            <br/>
            <div class="text-center">
              <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="isNewUsernameAvailable(derivedAccount.newUsername)">
                Criar subconta
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="card">
        <div class="card-body row">
          <div class="card-header">
            <h4 class="title">Subcontas criadas</h4>
          </div>

          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="derivedAccount.subAccounts"
                      style="width: 100%">

              <el-table-column :min-width="130" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.username}}</span>
                    </span>
                </template>
              </el-table-column>

              <el-table-column :min-width="130" label="Data de criação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createdDate}}</span>
                    </span>
                </template>
              </el-table-column>

            </el-table>
          </div>

        </div>
      </div>

    </div>

    <div class="col-lg-8 col-md-7">
      <!-- Bloco Dispositivo Não habilitado -->
      <div v-if="!hideTOTP && !isNullOrEmpty(totpQrCodeUri) && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true'">
        <card style="background-color: #00d19a; text-align: center">
          <h4 slot="header" class="card-title" style="color: #fff">Seu dispositivo não esta cadastrado!</h4>
          <card align="center" >
            <span>Para liberar suas transações siga os passos abaixo:</span>
            <div class="row"  v-if="isNullOrEmpty(totpQrCodeUri)">
              <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>
            </div>

            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="!isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 30px"><b>1</b> - Baixe e instale o Aplicativo <span style="color: black"><b style="color: #000000 !important;">SuitPay</b>.</span></p>
                <p><b>2</b> - Abra o Aplicativo e clique no botão <b>SuitID</b>.</p>
                <p><b>3</b> - Escaneie o QR Code encontrado no menu: <b>Habilitar Aplicativo</b>.</p>
                <p><b>4</b> - Pronto, seu aplicativo está habilitado para uso!</p><br>

                <span>Para download do aplicativo SuitPay acesse:</span><br><br>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/google_play.png" style="width: 120px">
                </a>
                <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                  <img border="0" alt="Android" src="/static/img/mobile/apple_store.png" style="width: 120px">
                </a>
              </div>

              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <span style="color: black; font-weight: bold">Google Authenticator</span></p>
                <p><b>2</b> - Procure por <b style="color: #000000 !important;">SuitPay</b> e seu nome de usuário no aplicativo </p>
                <p><b>3</b> - Informe, no campo abaixo, a senha exibida pelo aplicativo </p>
                <p><b>4</b> - Confirme a operação</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>
            <br>
            <div style="text-align: center">
              <p-button @click="()=>{hideTOTP = true; validationCode = ''}"
                        class="btn btn-danger" >
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
            </div>
          </card>
        </card>
      </div>

      <!-- Bloco Dispositivo Habilitado -->
      <div  v-if="!hideTOTP && !isNullOrEmpty(qrCode) && transactionAuthorizationTotp==='true' && isNullOrEmpty(totpQrCodeUri)">
        <card style="background-color: #00d19a; text-align: center; height: 100%">
          <h4 slot="header" class="card-title" style="color: #fff">Confirmação de Transação</h4>
          <card align="center" style="height: 100%" >

            <i style="margin: 0 auto; margin-top: 14px" class="fas fa-lock fa-5x text-warning"></i>

            <div class="row" style="padding: 3%">
              <div class="col-md-12" style="text-align: center" v-if="isNullOrEmpty(totpQrCodeUri)">
                <p style="margin-top: 14px"><b>1</b> - Abra o aplicativo <b>SuitPay</b>.</p>
                <p><b>2</b> - Selecione a opção <b>SuitId</b>.</p>
                <p><b>3</b> - Informe, no campo abaixo, o código exibido pelo aplicativo.</p>
                <p><b>4</b> - Confirme a operação.</p>
                <p><b>5</b> - Pronto!</p>
              </div>
            </div>

            <span>Informe o <b>SuitID</b>: </span>
            <input style="margin: 0 auto; margin-top: 14px" v-mask="['### ###']" v-model="validationCode" size="6"/>

            <div class="row" v-if="!codeValid">
              <p style="margin: 0 auto; margin-top: 14px" class="text-warning"> Código inválido</p>
            </div>

            <div style="text-align: center">
              <p-button @click="()=>{hideTOTP = true; validationCode = ''; subAccountInformationDisabled = false}"
                        class="btn btn-danger" >
                <i class="fas fa-chevron-left"/> Voltar
              </p-button>
              <p-button @click="()=>{validateCode()}"
                        class="btn btn-primary"
                        :disabled="totpConfirmBtnDisabled"
              >
                <i class="fas fa-chevron-right"/> Confirmar
              </p-button>
            </div>
          </card>
        </card>
      </div>

      <!-- Bloco informacoes -->
      <div v-if="!subAccountInformationDisabled">
        <div class="card">
          <div class="card-header">
            <h4 class="title" style="margin-top: 0px">Informações sobre subcontas</h4>
          </div>
          <div class="card-body">
            <div class="col-lg-12" style="margin-bottom: 30px">
              <p>A SuitPay esclarece que, de acordo com a legislação brasileira aplicável, ao solicitar o cadastro para a
                criação de uma subconta em nossa plataforma, o usuário declara estar ciente que, embora seja gerado um novo nome de usuário,
                a subconta será registrada com os mesmos dados informados no momento do cadastro da conta principal.</p>
              <p>Sendo assim, ambas as contas terão a mesma titularidade, entretanto, poderão operar atividades divergentes.</p>
              <p>Esta medida está alinhada com as normativas vigentes e visa garantir a transparência e a conformidade legal em
                todas as operações realizadas através da nossa plataforma.</p>
              <p>A Suitpay reitera o compromisso com a segurança e a integridade dos dados de nossos usuários, seguindo rigorosamente
                as diretrizes estabelecidas pelas autoridades competentes.</p>
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>


</template>
<script>
import swal from 'sweetalert2'
import {getWs, postWs} from "src/ws.service";
import {isNullOrEmpty} from "../../../../../util/core.utils";
import {notNull} from "../../../../../util/core.utils";
import {formatDate} from "../../../../../util/date.utils";

export default {
  components: {},
  props: ["derivedAccount"],
  created() {
    this.transactionAuthorizationTotp = notNull(localStorage.getItem('transactionAuthorizationTotp'), 'false')
  },
  data() {
    return {
      qrCode: '',
      totpQrCodeUri : '',
      transactionAuthorizationTotp: 'false',
      validateIntervalLoop: null,
      validationCode: '',
      totpConfirmBtnDisabled: false,
      codeValid: true,
      hideTOTP: true,
      subAccountInformationDisabled: false,
      totpCallback: null,
      messageUserUnavailableDisabled: true
    }
  },
  methods: {
    isNewUsernameAvailable(newUser) {
      postWs("/acc/validate-new-username",
        true,
        null,
        {newUsername: newUser},
        (response) => {this.createDerivedAccount()},
        (error) => {this.showMessageUserUnavailable()}
      )
    },
    showMessageUserUnavailable() {
      this.messageUserUnavailableDisabled = false
    },
    createDerivedAccount() {
      postWs("/acc/create-derived-account", true, null,
        {
          newUsername: this.derivedAccount.newUsername,
          suitIdEnabled: true,
          verifCode: this.verifCode
        },
        (response) => {
          if (!this.showTOTP(response, this.createDerivedAccount)) {
            this.clearTOTP()
            if (response.status === 201) {
              this.changeSuccess()
            } else if (response.status === 406) {
              this.changeError()
            }
          }
        }, error => {this.changeError()}
      )
    },
    isNullOrEmpty,
    validateCode(){
      //1 - quando clica no validate vem pra cá e valida
      this.totpConfirmBtnDisabled = true
      postWs("/verifcode/validate",
        true,
        null,
        {verifCode: this.qrCode, hashCode: this.validationCode},
        this.sucessValidateCode,
        this.failValidateCode)
    },
    sucessValidateCode(response) {
      //2 - após validar chama o callback
      this.totpConfirmBtnDisabled = false
      this.verifCode = response.data.verifCode
      this.hideTOTP = true
      this.qrCode = ''
      this.totpCallback()
    },
    confirmCode(response){
      postWs("/verifcode/confirm",
        true,
        null,
        {verifCode: response.data.verifCode},
        (response) => {
          this.success(response.data.authentication)
        },
        this.failConfirmCode)
    },
    failValidateCode() {
      this.totpConfirmBtnDisabled = false
      swal({
        title: 'Aviso!',
        text: 'Código inválido!',
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-warning btn-fill',
        type: 'warning'
      })
    },
    showTOTP(response, callback){
      if (response.data.response === 'TOTP_CODE_VALIDATION_REQUIRED' && !isNullOrEmpty(response.data.code)) {
        this.hideTOTP = false;
        this.qrCode = response.data.code
        this.totpQrCodeUri = response.data.totpQRCodeURI
        this.totpManualCode = response.data.totpManualCode
        this.totpCallback = callback
        this.subAccountInformationDisabled = true
        return true
      }
      return false
    },
    clearTOTP(){
      this.qrCode = null
      this.totpQrCodeUri = null
      this.totpManualCode = null
      this.totpCallback = null
      this.verifCode = null
      this.validationCode = null
    },
    success(authentication) {
      this.totpConfirmBtnDisabled = false
      this.authentication = authentication

      swal({
        title: 'Sucesso!',
        html: '<p>Código de Autenticação:<br>'+this.authentication+'</p>',
        buttonsStyling: true,
        confirmButtonClass: 'btn-success btn-fill',
        type: 'success',
        showCancelButton: true,
        confirmButtonColor: '#17a2b8',
        cancelButtonColor: '#00853c',
        confirmButtonText: 'Copiar Código',
        cancelButtonText: 'OK'
      }).then((result) => {
        if (result) {
          this.copyAuthenticationCode()
        }
      })
    },
    copyAuthenticationCode() {
      let authenticationCopy = document.querySelector('#authentication-copy')
      authenticationCopy.setAttribute('type', 'text')
      authenticationCopy.select()

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        if(msg === 'successful'){
          swal({
            text: 'Código de autenticação copiado!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          })
        }else{
          swal({
            text: 'Erro ao copiar código de autenticação!',
            buttonsStyling: true,
            confirmButtonClass: 'btn btn-warning btn-fill',
            type: 'warning'
          })
        }
      } catch (err) {
        swal({
          text: 'Erro ao copiar código de autenticação!',
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }

      /* unselect the range */
      authenticationCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    changeSuccess() {
      swal({
        title: 'Sucesso!',
        text: `Novo usuário @${this.derivedAccount.newUsername} criado com sucesso. Você já pode acessar a nova conta utilizando a mesma senha da conta
              principal e modificá-la`,
        buttonsStyling: true,
        timer: 10000,
        confirmButtonClass: 'btn btn-success btn-fill',
        type: 'success'
      })
      this.loadSubAccounts()
      this.clearTOTP()
      this.subAccountInformationDisabled = false
      this.messageUserUnavailableDisabled = true
    },
    changeError() {
      swal({
        title: 'Falha!',
        text: 'Falha ao criar sub-conta, favor entrar em contato com o suporte.',
        buttonsStyling: true,
        timer: 10000,
        confirmButtonClass: 'btn btn-danger btn-fill',
        type: 'error'
      })
      this.loadSubAccounts()
      this.clearTOTP()
      this.subAccountInformationDisabled = false
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    loadSubAccounts() {
      getWs("/acc/get-derived-accounts", true, null, (response) => {this.populateDerivedAccountInfo(response)}, function () {})
    },
    populateDerivedAccountInfo(response) {
      this.derivedAccount.newUsername = ''
      this.derivedAccount.suitIdEnabled = response.data.suitIdEnabled
      this.derivedAccount.isDerivedAccountEnabled = response.data.isDerivedAccountEnabled
      this.derivedAccount.subAccounts = response.data.subAccounts

      this.derivedAccount.subAccounts.forEach((sub) => {
        sub.createdDate = formatDate(new Date(sub.createdDate), "DD/MM/YYYY")
      })
      this.derivedAccount.subAccounts.sort()
    }
  }
}

</script>
<style>
</style>
